"use client";

import { startHolyLoader } from "holy-loader";
import { useRouter } from "next/navigation";
import type React from "react";
import Button, { type ButtonProps } from "src/components/common/Button/Button";
import { clearClientRouterCache } from "src/lib/ClearRouterCacheAction";
import { useAuth } from "src/lib/state/AuthContext";
export interface SignOutButtonProps extends Partial<ButtonProps> {}
const SignOutButton = (props: SignOutButtonProps) => {
  const {
    onClick,
    ...otherProps
  } = props;
  const auth = useAuth();
  const router = useRouter();
  return <Button label={"Sign out"} variant={"outline"} {...otherProps} onClick={async e => {
    onClick?.(e);
    if (!auth) {
      throw new Error("Missing auth");
    }
    startHolyLoader();
    auth?.signOut();
    await clearClientRouterCache();
    router.push("/");
  }} data-sentry-element="Button" data-sentry-component="SignOutButton" data-sentry-source-file="SignOutButton.tsx" />;
};
export default SignOutButton;