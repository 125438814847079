"use client";

import * as Sentry from "@sentry/nextjs";
import React, { useEffect } from "react";
import { PLAN_TYPE_INFO, type PlanType } from "shared/Plans";
import type { AuthState } from "shared/SharedTypes";
import { postAuthSignOut } from "src/Api";
import { clearClientRouterCache } from "src/lib/ClearRouterCacheAction";
export interface AuthContextState {
  signIn: (state: AuthState) => Promise<void>;
  signOut: () => Promise<void>;
  authState: AuthState | undefined;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState | undefined>>;
}
const AuthContext = React.createContext<AuthContextState | undefined>(undefined);
export interface AuthProviderProps {
  children: React.ReactNode;
  defaultAuthState: AuthState | undefined | null;
}
export const AuthProvider = (props: AuthProviderProps) => {
  const {
    children,
    defaultAuthState
  } = props;
  const [authState, setAuthState] = React.useState<AuthState | undefined>(defaultAuthState ?? undefined);
  useEffect(() => {
    if (authState?.user) {
      Sentry.setUser({
        id: authState.user.id,
        username: `${authState.user.firstName} ${authState.user.lastName}`,
        email: authState.user.email
      });
    }
  }, [authState]);
  return <AuthContext.Provider value={{
    authState: authState,
    setAuthState,
    signIn: async (state: AuthState) => {
      setAuthState(state);
      await clearClientRouterCache();
    },
    signOut: async () => {
      setAuthState(undefined);
      await postAuthSignOut.submit({});
      await clearClientRouterCache();
    }
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthContext.tsx">
      {children}
    </AuthContext.Provider>;
};
export const useAuth = (): AuthContextState | undefined => {
  const context = React.useContext(AuthContext);
  return context;
};
export const useCurrentPlan = (): PlanType | null => {
  const auth = useAuth();
  return auth?.authState?.subscription?.planTypeId ? PLAN_TYPE_INFO[auth.authState.subscription.planTypeId] : null;
};
export default AuthContext;